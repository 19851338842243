import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  API_END_POINT_ACCOUNTS:string;
 

  constructor(private httpClient: HttpClient) {
    this.API_END_POINT_ACCOUNTS = localStorage.getEncryptedItem('API_END_POINT_ACCOUNTS');
   }
  getUserImg(accID){
    return this.httpClient.post(this.API_END_POINT_ACCOUNTS+`users/fetch-image`,accID)
  }
}
