 <aside id="left-panel">

     <nav>
         <!-- NOTE: Notice the gaps after each icon usage <i></i>..
    Please note that these links work a bit different than
    traditional href="" links. See documentation for details.
    -->
         <br>
         <div class="profile-image-box">
             <div class="gold" [ngClass]="imageurl=='' || imageurl==undefined?'setnavBackgroundImg':''">
                 <!-- <img src="assets/img/avatars/1.png"
                alt="Hugh Jackman" class="body-image profile-pic"> -->
                <img *ngIf="imageurl!='' && imageurl!=undefined" [src]="imageurl ? imageurl:''" alt="Image"  class="body-image profile-pic-image">
                 <!-- <img src="assets/img/avatars/1.png" alt="Hugh Jackman"
                     class="body-image modalImage"> -->

             </div>
         </div>
         <div class="text-center"><label class="userlabel">{{userName}}</label></div>
         <div class="text-center mb-4"><label class="company-label" id="help_text" (click)="goToAdmin()">{{companyName}}</label></div>
         <ul saSmartMenu>
             <li routerLinkActive="active">
                 <a title="{{'profile_myProfile' | i18n}}" routerLink="/profile">
                     <i class="fas fa-user" style="font-size: 17px !important;"></i>
                     <span class="menu-item-parent pl-3">{{'profile_myProfile' | i18n}}</span></a>
             </li>
             <!-- <li routerLinkActive="active">
                <a title="{{'companymanager' | i18n}}">
                    <i class="fas fa-building"></i>
                    <span class="menu-item-parent pl-3">{{'companymanager' | i18n}}</span>
                </a>
                <ul style="padding: 0;">
                    <li routerLinkActive="active">
                        <a routerLink="/companymanager/companyprofile" style="padding-top: 12px !important;padding-bottom: 12px !important;">
                            {{'company Profile' | i18n}}
                        </a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/companymanager/usermanagement" style="padding-top: 12px !important;padding-bottom: 12px !important;">
                             {{'account_userManagemet' | i18n}} 
                        </a>
                    </li>
                </ul> 
            </li> -->
             <!--  <li routerLinkActive="active">
                <a title="{{'company Profile' | i18n}}" routerLink="/companymanager/companyprofile">
                    <i class="fas fa-building"></i>
                    <span class="menu-item-parent pl-3"> {{'Company Profile' | i18n}}</span>
                </a>
            </li>
            <li routerLinkActive="active">
                <a title="{{'account_userManagemet' | i18n}}" routerLink="/companymanager/usermanagement">
                    <i class="fas fa-users"></i>
                    <span class="menu-item-parent pl-3"> {{'account_userManagemet' | i18n}}</span>
                </a>
            </li>
             <li routerLinkActive="active">
                <a title="{{'account_companyManagemet' | i18n}}" routerLink="/companymanagement">
                    <i class="fas fa-building" style="font-size: 17px !important;"></i>
                    <span class="menu-item-parent pl-3">{{'account_companyManagemet' | i18n}}</span>
                </a>
            </li>-->


         </ul>
     </nav>

     <div class="navFooter">
         <div class="colDisplay">
             <!-- <div class="helpSection footerTextColor">
                 <div>
                     <i class="fa fa-question-circle" aria-hidden="true" style="font-size: 20x;"></i>
                     <span id="help_text" title="{{'common_help' | i18n}}"  style="font-size: 14px;padding-left: 10px;cursor: pointer;">{{'common_help' | i18n}}</span>
                 </div>
             </div> -->
             <div class="logoutSection">
                 <sa-clearCache></sa-clearCache>
             </div>
         </div>
     </div>
 </aside>