import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class CommonService {
    private localKeys = {
        "language": "language",
        "gender": "gender",
        "chatInfo": "chatInfo",
        "countries": "countries",
        "packages": "packages",
        "dateFormat": "dateFormat"
    };
    constructor(private httpClient: HttpClient) { }
    linkCode="";
    getCurrentLanguageCode() {
        let currentLang = localStorage.getEncryptedItem("current_lang");
        let lang = "en";
        if (currentLang) {
            currentLang = JSON.parse(currentLang);
            lang = currentLang["key"];
        }
        return lang;
    }
    fetchLanguage(language) {
        let langData = this.getCommonDataOnLanuguage(language, this.localKeys.language);
        if (!langData || this.getCurrentLanguageCode() != language) {
            var formData = new FormData();
            formData.append('userId', '');
            formData.append("loggedInTime", '');
            formData.append("lastRequestTime", '');
            return this.httpClient.get("assets/api/languages.json").pipe(map(res => {
                this.setCommonDataOnLanguage(language, this.localKeys.language, res["DATA"]);
                return res["DATA"];
            }));
        } else {
            return new Observable((observable) => {
                observable.next(langData);
                observable.complete();
            });
        }
    }
    getCommonDataOnLanuguage(language, key) {
        let commonData = localStorage.getEncryptedItem("common-data");
        let retData = null;
        if (commonData) {
            try {
                commonData = JSON.parse(commonData);
                let commonLangData = commonData[language];
                if (commonLangData && commonLangData[key]) {
                    retData = commonLangData[key];
                }
            }
            catch (e) {
                retData = null;
                throw "Error in parsing commondata from localStorage";
            }
            finally {
            }
        }
        return retData;
    }
    setCommonDataOnLanguage(language, key, data) {
        let commonData: any = localStorage.getEncryptedItem("common-data");
        let langData;
        if (commonData) {
            commonData = JSON.parse(commonData);
            commonData[language] = commonData[language] || {};
            langData = commonData[language];
        } else {
            commonData = {};
            commonData[language] = {};
            langData = commonData[language];
        }
        langData[key] = data;
        localStorage.setEncryptedItem("common-data", JSON.stringify(commonData));
    }

    getLanguageFromLangId(id) {
        let lanId = this.getCurrentLanguageCode();
        let languages = this.getCommonDataOnLanuguage(lanId, this.localKeys.language);
        for (let i = 0; i < languages.LANGUAGES.length; i++) {
            if (languages.LANGUAGES[i].CODE == id) {
                return languages.LANGUAGES[i].NAME
            }
        }
    }

    setLinkdInCode(LinkdInCode) {
        this.linkCode = LinkdInCode;
        if (this.linkCode != undefined) {
            return this.linkCode;
        }
        else {
            return "";
        }
    }
}