import {Component, OnInit, Renderer2, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import { LayoutService } from '@app/core/services';
import { UserService } from '@app/core/services';
import { CommonService } from '@app/core/services/common.service';
import { I18nService } from '@app/shared/i18n/i18n.service';
import { Subscription } from 'rxjs';
import { environment } from '@env/environment';
import { BsModalRef,ModalDirective } from 'ngx-bootstrap';
import { EmitService } from '@app/ts/services/emit.service';
import { DomSanitizer } from '@angular/platform-browser';
declare var $: any;

@Component({
  selector: 'sa-header',
  templateUrl: './header.component.html',
  styleUrls:['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild('newBuild') public newBuild:ModalDirective;
  @ViewChild('cookieError') public cookieError:ModalDirective;
  store: any;
  light: any;
  dark: any;
  loadedDarkOptions;
  loadedDarkprofile;
  loadedLightOptions;
  loadedLightprofile;
  lanId: any;
  company;
  langChangeSubscription: Subscription;
  langaugeChanged: boolean = false;
  activitySelected:boolean = false;
  fullScreenSelected:boolean = true;
  notificationSelected:boolean = false;
  companyConfirmed: any;
  showCompanyAlert:boolean=false;
  isBexioUser: boolean = false;
  gotoMTC: boolean = false;
  headerColor = environment.color;
  buildAlert:String ="";
  showerrorAlert:boolean=false;
  logo: string;
  isEpost;
  defaultConfig: any;
  // permissions;
  constructor(private router: Router,private layoutService: LayoutService,private i18nService: I18nService,
    private commonService:CommonService,private userService: UserService,
    private emitService: EmitService, private renderer: Renderer2,private _sanitizer: DomSanitizer) {
    // this.permissions = localStorage.getEncryptedItem("userPermissions").toString();
    this.langChangeSubscription = this.i18nService.langChangeEvent.subscribe(langObj => 
      setTimeout(() => {
        this.onLangChange(langObj);
      },500));
      let style =  this.commonService.getCookie(this.commonService.encrypt('styleCookie'));
      let styleTheme = this.commonService.decrypt(style)
      styleTheme ? this.layoutService.onSmartSkin(JSON.parse(styleTheme)):'';
      this.defaultConfig = localStorage.getEncryptedItem('configData');
      let status;
      setTimeout(() => {
        console.log("environment.LOGO.dark[localStorage.getEncryptedItem('domain')]",environment.LOGO.dark[localStorage.getEncryptedItem('domain')]);
        
        styleTheme?JSON.parse(styleTheme).name == 'smart-style-2' ? ($("#themeStyle").prop("checked",true)): ($("#themeStyle").prop("checked",false)):'';
       });
        // styleTheme?JSON.parse(styleTheme).name == 'smart-style-2' ? (this.logo = environment.LOGO.light[localStorage.getEncryptedItem('domain')]): ():'';
        this.logo = $("body").hasClass("smart-style-1") ?  environment.LOGO.dark[localStorage.getEncryptedItem('domain')] : environment.LOGO.light[localStorage.getEncryptedItem('domain')];
        console.log('this.logo',this.logo);
        this.isEpost = localStorage.getEncryptedItem('domain').indexOf('epost') != -1 ? 'ePost' : ''; 
       this.emitService.newDistEvent.subscribe(data=>{
        if(data){
          this.buildAlert = this.i18nService.getTranslation('build_alert');
          this.newBuild.show();
        }
        
      })
      this.emitService.refreshApiErrorEvent.subscribe(data=>{
        // if(data)
        //   this.cookieError.show();
      })
  }
  onLangChange(langObj: any) {
    langObj.isHandled = true;
    this.loadedDarkOptions = false;
    this.loadedDarkprofile = false;
    this.loadedLightprofile = false;
    if( this.langaugeChanged ){
        setTimeout(() => {
          this.lanId = this.commonService.getCurrentLanguageCode();
          this.loadDarkProfile();
          this.loadDarkOptions();
        },1000);
        
    }
    
    this.langaugeChanged = true;
  }

  selectActivity(data){ 
    // this.activitySelected = data;
    if(data.type=='notification'){
      this.notificationSelected = data.value;
    }
    else
      this.activitySelected = data.value;
  }
  selectFullScreen(data){
    this.fullScreenSelected = data;
  }
  
  ngOnInit() {
    this.showerrorAlerts();
    this.companyApprove();
    localStorage.getEncryptedItem('isBexioCompany')=='true'?this.isBexioUser=true:this.isBexioUser=false;
    this.store = this.layoutService.store;
    let skins = this.store.skins;
    for (let i = 0; i < skins.length; i++) {
      if(skins[i].name == 'smart-style-1'){
        this.dark = skins[i];
        // this.logo = environment.LOGO.dark[localStorage.getEncryptedItem('domain')];
      }     
      if(skins[i].name == 'smart-style-2'){
        this.light = skins[i];
        // this.logo = environment.LOGO.light[localStorage.getEncryptedItem('domain')];
      } 
    }
    let mtc_cookie = this.commonService.getCookie(environment.mtc_cookie);
    if( mtc_cookie != "" || mtc_cookie){
      this.gotoMTC = true;
    }
    else{
      this.gotoMTC = false;
    }
    setTimeout(() => {
      this.loadDarkOptions();
      this.loadDarkProfile();
      this.emitService.whitelabelConfig.subscribe( data => {
        if(data){
          this.getImage();
        }
       
      });
    },1000);
    this.buildAlert = this.i18nService.getTranslation('build_alert')
    // this.loadLightOptions();
    // this.loadLightProfile();
  }


  loadDarkOptions(): void {
    if (this.loadedDarkOptions) return;
    const script = document.createElement('script');
    // script.src = 'assets/webComponent/options.js';
    script.src = environment.WC_APPS;
    document.body.appendChild(script);
    this.loadedDarkOptions = true;
    const content = document.getElementById('webComponetsOptions');
    this.lanId = this.commonService.getCurrentLanguageCode();
    let optiondata: any = {isWork: true,lang:this.lanId};
    optiondata = JSON.stringify(optiondata);
    content.innerHTML = `<custom-button optiondata='${optiondata}'></custom-button>`;
  }
  loadDarkProfile(): void {
    if (this.loadedDarkprofile) return;
    const script = document.createElement('script');
    // script.src = 'assets/webComponent/profile.js';
    script.src = environment.WC_PROFILE;
    document.body.appendChild(script);
    this.loadedDarkprofile = true;
    const content = document.getElementById('webComponetsProfile');
    this.lanId = this.commonService.getCurrentLanguageCode();
    let profileData: any = {lang: this.lanId};
    profileData = JSON.stringify(profileData);
    content.innerHTML = `<profile-button profiledata='${profileData}'></profile-button>`;
  }

  searchMobileActive = false;

  toggleSearchMobile(){
    this.searchMobileActive = !this.searchMobileActive;

    $('body').toggleClass('search-mobile', this.searchMobileActive);
  }

  onSubmit() {
    this.router.navigate(['/miscellaneous/search']);

  }

  routeToProfile(){
    this.router.navigate(['/profile']);
  }
  onSelectTheme(e){

    if(e.currentTarget.checked == true){

     this.commonService.setCookie(this.commonService.encrypt(environment.styleCookie),this.commonService.encrypt(JSON.stringify(this.light)),10000,environment.cookieDomain,'/')
      this.layoutService.onSmartSkin(this.light)
      $('.menuIconsOption').siblings('div').first().removeClass(this.dark.name).addClass(this.light.name);
      $('.menuIcons').siblings('div').first().removeClass(this.dark.name).addClass(this.light.name);  
      console.log('environment.LOGO',environment.LOGO);
       
      this.logo = environment.LOGO.light[localStorage.getEncryptedItem('domain')];
      console.log(this.logo);

    }
    else{
      this.commonService.setCookie(this.commonService.encrypt(environment.styleCookie),this.commonService.encrypt(JSON.stringify(this.dark)),10000,environment.cookieDomain,'/')
      this.layoutService.onSmartSkin(this.dark)
      $('.menuIconsOption').siblings('div').first().removeClass(this.light.name).addClass(this.dark.name);
      $('.menuIcons').siblings('div').first().removeClass(this.light.name).addClass(this.dark.name);
      this.logo = environment.LOGO.dark[localStorage.getEncryptedItem('domain')];
      console.log(this.logo);
      
    }
    const currentTheme = $("body").hasClass("smart-style-1") ? 'dark_theme' : 'light_theme';
    let config = JSON.parse(localStorage.getEncryptedItem('configData'));
    let style = config['color'];
    for (const [cssVar, themeColors] of Object.entries(style)) {
      // Set the CSS variable for the current theme
      if (themeColors[currentTheme]) {
          document.documentElement.style.setProperty(cssVar, themeColors[currentTheme]);
      }
    }
    this.getImage();
    
    /* this.setConfigData(); */
    this.loadedDarkprofile = false;
    // $('profile-button').remove();
    // this.loadDarkProfile();
    this.loadedDarkOptions = false;
    // $('custom-button').remove();
    // this.loadDarkOptions();
    
  }
  companyApprove(){
    this.companyConfirmed  = localStorage.getEncryptedItem('isCompanyConfirmed');
    // this.companyConfirmed = false;
    if(this.companyConfirmed != 'true' && !localStorage.getEncryptedItem('companyAlert')){
      this.showCompanyAlert = true;
      localStorage.setEncryptedItem("companyAlert","true");
    }else{
      this.showCompanyAlert = false;
    }
  }

  backToBexio(){
    window.open(environment.BEXIO_URL, '_self')
    return false;
  }
  backToMTC(){
    window.open(environment.MTC_URL+'companyManagement', '_self');
    return false;
  }
  backToGate2b(){
    window.open(environment.GATE2B_URL[localStorage.getEncryptedItem('domain')], '_self');
    return false; 
  }
  clearCache() {
    let accounts_url = localStorage.getEncryptedItem('ACCOUNTS_URL');
    let accounts_api_url = localStorage.getEncryptedItem('API_END_POINT_ACCOUNTS');
    localStorage.clear();
    localStorage.setEncryptedItem('ACCOUNTS_URL',accounts_url);
    localStorage.setEncryptedItem('API_END_POINT_ACCOUNTS',accounts_api_url);
    let cookies = document.cookie.split("; ");
    for (let c = 0; c < cookies.length; c++) {
      let d = window.location.hostname.split(".");
      while (d.length > 0) {
        let cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
        let p = location.pathname.split('/');
        document.cookie = cookieBase + '/';
        while (p.length > 0) {
          document.cookie = cookieBase + p.join('/');
          p.pop();
        };
        d.shift();
      }
    }
    location.reload()
  }
  showerrorAlerts(){
    this.emitService.refreshApiErroralert.subscribe(data=>{
      if(data){
        console.log(data);
        this.showerrorAlert= true 
        // location.reload()
         
      }
    });
    
  }

  errorAlertOk(){
    this.cookieError.hide();
    let domain = localStorage.getEncryptedItem('domain');
    let accounts_url = localStorage.getEncryptedItem('ACCOUNTS_URL');
    let accounts_api_url = localStorage.getEncryptedItem('API_END_POINT_ACCOUNTS');
    localStorage.clear();
    localStorage.setEncryptedItem('ACCOUNTS_URL',accounts_url);
    localStorage.setEncryptedItem('API_END_POINT_ACCOUNTS',accounts_api_url);
    window.location.href = accounts_url+'auth/logout/'+encodeURIComponent(this.commonService.encrypt(window.location.href))
  }



  getImage() {  
    console.log(Date())
    const currentTheme = $("body").hasClass("smart-style-1") ? 'dark_theme' : 'light_theme';
    let imageData = this.commonService.getWhiteLabelImageData(currentTheme);
    let logo_div = document.querySelector('#logo');
    let logo_url = imageData['dataUrl'];
    this.renderer.setStyle(logo_div,'backgroundImage', `url(${logo_url})`);
    
    /*     let headerLogo = this.defaultConfig?.['images']?.[currentTheme]?.['filePath'];
    if( headerLogo ){
      let formData = new FormData();
      formData.append('filePath', headerLogo);
      let object = this.commonService.createObject(formData);
      let token = this.commonService.createToken(object);
      let encryptedToken = this.commonService.encrypt(token);
      let encrData = new FormData();
      encrData.append('encrToken', encryptedToken);
      this.userService.getCompanyLogoImages(encrData).subscribe(data => {
        
      })
    } */
  }
  /* setConfigData(){
    
    let logo_div = document.querySelector('#logo');
    let logo_url = this.defaultConfig['images'][currentTheme]['dataUrl'];
    this.renderer.setStyle(logo_div,'backgroundImage', `url(${logo_url})`);
    let style = this.defaultConfig['color'];
    for (const [cssVar, themeColors] of Object.entries(style)) {
      // Set the CSS variable for the current theme
      if (themeColors[currentTheme]) {
          document.documentElement.style.setProperty(cssVar, themeColors[currentTheme]);
      }
    }

    const styleElement = document.createElement('style');
    styleElement.textContent = style.extraStyle;
    document.head.appendChild(styleElement);
  } */
}
