import { Component, OnInit, ViewChild } from "@angular/core";
import { NotificationService } from "@app/core/services/notification.service";
import { I18nService } from '@app/shared/i18n/i18n.service';
import { environment } from "@env/environment";
import { BsLocaleService, BsModalRef, ModalDirective } from 'ngx-bootstrap';

declare var $: any;

@Component({
  selector: "sa-companyApprove",
  template: `
<div bsModal #smUpdateModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
  aria-hidden="true" id="myModal">
  <div class="modal-dialog modal-sl modal-dialog-centered">
      <div class="modal-content modalBodyBgColor border" style="padding: 15px !important;  width: 800px;">
      <header class="">                                        
          <div class="pull-right crossDiv">
              <a (click)="smUpdateModal.hide();" title="{{'common_close' | i18n}}">
                  <i class="fa fa-close crossBtn" aria-hidden="true"></i>
              </a>
          </div>
      </header>
          <div class="modal-body modalBody p-0">
              <div class="modal-body modalBody payBody">
                  <h4>{{ common_companyapprove_alert |i18n}}</h4>
              </div>
              <div class="MessageBoxButtonSection">
                
                <button id="bot2-Msg1" class="btn btn-default btn-sm botTempo" (click)="smUpdateModal.hide();">{{'common_ok'|i18n}}</button>
              </div>
          </div>
      </div>
  </div>
</div>
  `,
  styles: ['.crossDiv{margin-top: -10px;margin-right: -7px;}.crossbtnDiv{ margin-top: 12px;margin-right: 2px;}.crossBtn{text-align: right;letter-spacing: 0px; opacity: 1;font-size: 20px;font-weight: bold; }.modal_header {text-align: left;font: normal normal bold 16px/22px Open Sans !important;letter-spacing: 0px;opacity: 1;}']
})
export class CompanyApproveComponent implements OnInit {

  public user
  userName = "";
  public modalRef: BsModalRef;
  @ViewChild('smUpdateModal') public smUpdateModal:ModalDirective;
  common_companyapprove_alert: any;
  langChangeSubscription: any;

  constructor(
    private notificationService: NotificationService,
    private i18nService: I18nService
  ) {
    this.langChangeSubscription = this.i18nService.langChangeEvent.subscribe(langObj => this.onLangChange(langObj));
  }
  onLangChange(langObj: any) {
    // setTimeout(() => {
    //   let domain = localStorage.getEncryptedItem('domain');
    //   let companyName = environment.COMPANY_NAME[domain].replace(/ AG$/, '');
    //   this.common_companyapprove_alert = this.i18nService.getTranslation('register_newletter').replace('$company_name$',companyName);
    // },500)
    langObj.isHandled = true;
    
  }


  
  
  ngOnInit() { 
    let domain = localStorage.getEncryptedItem('domain');
    // let companyName = environment.COMPANY_NAME[domain].replace(/ AG$/, '');
    let configData = localStorage.getEncryptedItem('configData');
    let config = JSON.parse(configData);
    let companyName = config.company_name.replace(/ AG$/, '');
    this.common_companyapprove_alert = this.i18nService.getTranslation('register_newletter').replace('$company_name$',companyName);
    setTimeout(() => {
      this.showPopup()
    },50);
  }
  showPopup() {
    this.smUpdateModal.show();
    $('#myModal').appendTo("body").modal('show');
   
  }

}
