import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from "@env/environment";
import { CommonService } from '../services/common.service';
import { map } from 'rxjs/operators';

@Injectable()
export class RoleResolveService implements Resolve<any> {

  constructor(private http: HttpClient,private commonService: CommonService,private router: Router) { }
  resolve(): Observable<any> {
    let cookieExist = this.commonService.getCookie(environment.checkCookie);

    if (cookieExist == "") {
      this.router.navigate(['/auth/login'])
    }
    else{
      let formData = new FormData();
      formData.append('code','ACCOUNTS')
      let objectUser = this.commonService.createObject(formData);
      let tokenUser = this.commonService.createToken(objectUser);
      let encryptedTokenUser = this.commonService.encrypt(tokenUser);
      let encrDataUser = new FormData();
      encrDataUser.append("encrToken", encryptedTokenUser);
      return this.http.post(localStorage.getEncryptedItem('API_END_POINT_ACCOUNTS')+'users/user-profile',encrDataUser)
      .pipe(map((dataFromApi) => {
        let user = dataFromApi['data'];
        if( !user[0].hasPermission || !user[0].isConfirmedParty){
          let accounts_url = localStorage.getEncryptedItem('ACCOUNTS_URL');
          let accounts_api_url = localStorage.getEncryptedItem('API_END_POINT_ACCOUNTS');
          localStorage.clear();
          localStorage.setEncryptedItem('ACCOUNTS_URL',accounts_url);
          localStorage.setEncryptedItem('API_END_POINT_ACCOUNTS',accounts_api_url);
          window.location.href = accounts_url+'auth/logout/'+encodeURIComponent(this.commonService.encrypt(window.location.href))
        }
        let permissions = this.commonService.encrypt(String(user[0].permissions.Allow));
        localStorage.setEncryptedItem('permissions',permissions);
        localStorage.setEncryptedItem('userData', JSON.stringify(user));
        localStorage.setEncryptedItem('accId',user[0].accId);
        localStorage.setEncryptedItem('isCompanyConfirmed',user[0].isConfirmedParty);
        localStorage.setEncryptedItem('isAddressEmpty',user[0].isAddressEmpty);
        localStorage.setEncryptedItem('hasPermission',user[0].hasPermission);
       }));
    }
    
  }
}
