import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { BehaviorSubject } from "rxjs";
import { JsonApiService } from "@app/core/services/json-api.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";



// const defaultUser = {
//   username: "Guest"
// }
@Injectable()
export class UserService {
  // public user$ = new BehaviorSubject({...defaultUser});
  httpOptions = {
    headers: new HttpHeaders(),
    withCredentials: true
  };
  API_END_POINT_ACCOUNTS: string;
  constructor(private httpClient: HttpClient) {
    // this.jsonApiService.fetch("/user/login-info.json").subscribe(this.user$)
    this.API_END_POINT_ACCOUNTS = localStorage.getEncryptedItem('API_END_POINT_ACCOUNTS');
  }

  // public logout(){
  //   this.user$.next({...defaultUser})
  // }
  logoutUsers$(data) {
    return this.httpClient.post(this.API_END_POINT_ACCOUNTS + 'users/logout', data, this.httpOptions).pipe(map((response: Response) => response));

  }

  userData(data){
    return this.httpClient.post(this.API_END_POINT_ACCOUNTS+'users/user-profile',data);
  }
  getCommissionAgentConfig(data){
    return  this.httpClient.post(this.API_END_POINT_ACCOUNTS+'users/getCommissionAgentConfig',data).pipe(map((response:Response)=>response))
  }
  getCompanyLogoImages(data){
    return this.httpClient.post(this.API_END_POINT_ACCOUNTS+'auth/getCompanyLogoImages',data).pipe(map((response:Response)=>response));
  }
}
