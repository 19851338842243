import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonService } from '@app/core/services/common.service';
import { EmitService } from '@app/ts/services/emit.service';
import { environment } from '@env/environment';
import * as jwt from "jsonwebtoken";

declare var $: any;
@Component({
  selector: 'sa-footer',
  templateUrl: './footer.component.html',
  styleUrls:['footer.component.scss']
})
export class FooterComponent implements OnInit {
  year = new Date().getFullYear();
  devOptionsRight:boolean = false;
  showDevOptions:boolean = false;
  pagePermission: any;
  company;
  constructor(private commonService: CommonService,private emitService: EmitService,private cdr: ChangeDetectorRef) {
    let permissionCookie = this.commonService.getCookie(environment.permissions);
    this.pagePermission = this.commonService.decrypt(decodeURIComponent(permissionCookie)).split(',');
    this.company = environment.FOOTER_TEXT[localStorage.getEncryptedItem('domain')];
  }

  ngOnInit() {
    this.devOptionsRight = this.pagePermission.includes("admin.company.*");
    this.emitService.whitelabelConfig.subscribe( data => {
      if(data){
        if( Object.keys(data).length != 0){
          let domain = localStorage.getEncryptedItem('domain');
          let configData = localStorage.getEncryptedItem('configData');
          let config = JSON.parse(configData) || data;
          this.company = config.company_name.replace(/ AG$/, '');
          this.cdr.detectChanges();
        }
      }
    });
  }
  openDevOption() {
    // $('#decodepopup').dialog("open");
    this.showDevOptions = !this.showDevOptions;
  }
  clear() {
    $('#encrypted').val('');
    $('#decrypted').val('');
  }

  decode() {
    let errorVal = '';
    let decryptedToken;
    if($('#encrypted').val()!='') {
      try{
        decryptedToken = this.commonService.decrypt($('#encrypted').val())
      }
      catch(Error){
        errorVal = "Not a valid Token";
      }
      if(errorVal==''){
        $('#decrypted').val(JSON.stringify(jwt.decode(decryptedToken), null, 2))
      }
      else{
        $('#encrypted').val(errorVal);
      }

    }
  }
}
