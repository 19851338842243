<div class="row devOptions"  *ngIf="showDevOptions&&devOptionsRight">
    <!-- <ul class="nav nav-tabs in">
      <li [class.active]="state.tabs.demo1=='0'">
        <a (click)="state.tabs.demo1 = '0'">
          <span>{{'common_all_decode' | i18n}} </span>
        </a>
      </li>
      <li [class.active]="state.tabs.demo1=='1'">
        <a (click)="state.tabs.demo1 = '1'">
          <span> {{'Translation' | i18n}}</span>
        </a>
      </li>
      <i class="fa fa-window-minimize pull-right margin-top-10 margin-right-8" (click)="showDevOptions=false" style="cursor: pointer;"></i>
    </ul> -->
  
    <!-- <tabset class="padding-10">
      <tab [active]="state.tabs.demo1 == 0"> -->
        <div class="p-4 w-100">
            <div class="w-100">
                <h4 class="ml-3" style="font-weight: 600">{{'common_all_decode' | i18n}} </h4>
                <i class="fa fa-window-minimize margin-top-10 margin-right-8" (click)="showDevOptions=false" style="cursor: pointer; position: absolute; right: 10px; top: 5px;"></i>
            </div>
            <div class="clearfix"></div>
            <div class="w-100" style="font-size: 14px;">
                <textarea class="decodeTextArea m-3" id="encrypted" placeholder="{{'common_all_encryptedData' | i18n}}"></textarea>
                <textarea class="decodeTextArea m-3" id="decrypted" placeholder="{{'common_all_result' | i18n}}" disabled></textarea>
            </div>
            <hr>
            <div class="col-sm-12 text-right mb-4">
              <footer>
                <button title="{{'common_clear' | i18n}}" (click)="clear()"
                  class="itemEditButton"  style="font-size: 14px;">{{'common_clear' | i18n}}</button>
                <button title="{{'common_submit' | i18n}}" (click)="decode()" type="button"
                  class="itemEditButton ml-4" style="font-size: 14px;"><i class="fa fa-spinner fa-spin"
                    *ngIf="addStatus"></i>{{'common_submit' | i18n}}</button>
              </footer>
            </div>
        </div>
        
      <!-- </tab>
      <tab [active]="state.tabs.demo1 == 1" style="color:#333;padding: 0px 15px;"> 
        Will be updated soon
      </tab>
    </tabset> -->
  </div>
<div class="page-footer theme-bg-color w-100">
    <!-- <div class="row"> -->

    <!-- </div> -->
    <div class="row my-2 mx-1">
        <!-- <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 iconItems">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 helpIcon">
               <div><i class="fa fa-question-circle" aria-hidden="true" style="color: white;font-size: 20x;"></i><span style="font-size: 14px;color: #4b515a;padding-left: 10px;">Help</span></div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <sa-logout></sa-logout>
            </div>
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 px-0">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8">
                <div class="row">
                    <ul class="footerItems footerTextColor">
                        <li>
                            <span class="li-footer__copy-text">© {{year}} </span>
                            <span class="footerTxt">{{company}}</span>
                        </li>
                        <li class="footerContent">
                            <a href="#/termsandcondition" target="_blank"> <span class="li-footer__copy-text">{{'common_all_terms_and_conditions' | i18n}}</span></a>

                        </li>
                        <li class="footerContent" >
                            <a href="#/privacy-policy" target="_blank"><span class="li-footer__copy-text">{{'common_all_privacy_policy' | i18n}}</span></a>

                        </li>
                        <li class="footerContent">
                            <a href="#/legal-notices" target="_blank"> <span class="li-footer__copy-text">{{'common_all_legal_notices' | i18n}}</span></a>

                        </li>
                    </ul>
                </div>
                <!-- <span class="txt-color-white">SmartAdmin 1.9.0 - Web Application Framework © 2017-2019</span> -->
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 px-0 hidden-xs d-sm-none  d-md-none d-lg-block">
                <a title="{{'common_all_footer_devOptions' | i18n}}" *ngIf="devOptionsRight" (click)="openDevOption()" class="itemEditButton" id="reset-smart-widget" style="position: absolute;
                bottom: -22px;
                right: 5px;">
                    <span class="decode">{{'common_all_footer_devOptions' | i18n}}</span></a>
                
            </div> 
            <!-- <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 text-right hidden-xs d-sm-none d-lg-block .d-xl-block">
                <div class="txt-color-white inline-block">
                    <div class="btn-group" dropdown dropup="true">
                        <ul class="dropdown-menu" *dropdownMenu style="right: 0px; left: auto">
                            <li>
                                <div class="padding-5">
                                    <p class="txt-color-darken font-sm no-margin">Download Progress</p>

                                    <div class="progress progress-micro no-margin">
                                        <div class="progress-bar progress-bar-success" style="width: 50%;"></div>
                                    </div>
                                </div>
                            </li>
                            <li class="divider"></li>
                            <li>
                                <div class="padding-5">
                                    <p class="txt-color-darken font-sm no-margin">Server Load</p>

                                    <div class="progress progress-micro no-margin">
                                        <div class="progress-bar progress-bar-success" style="width: 20%;"></div>
                                    </div>
                                </div>
                            </li>
                            <li class="divider"></li>
                            <li>
                                <div class="padding-5">
                                    <p class="txt-color-darken font-sm no-margin">Memory Load <span class="text-danger">*critical*</span>
                                    </p>

                                    <div class="progress progress-micro no-margin">
                                        <div class="progress-bar progress-bar-danger" style="width: 70%;"></div>
                                    </div>
                                </div>
                            </li>
                            <li class="divider"></li>
                            <li>
                                <div class="padding-5">
                                    <button class="btn btn-block btn-default">refresh</button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>