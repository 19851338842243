import { Injectable, EventEmitter, Output } from '@angular/core';
// import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class EmitService {

  private _emitter;
  private _emitter_removegrpmember;
  public previousUrl;
  //To readjust table
  private readjustDataTableSubject: BehaviorSubject<any>;
  private readjustDataTableEvent: any;

  //To refresh table
  private refreshDataTableSubject: BehaviorSubject<any>;
  private refreshDataTableEvent: any;
  @Output() langLoginChange: EventEmitter<any> = new EventEmitter();
  @Output() reloadDygraph: EventEmitter<any> = new EventEmitter();
  private userLangChange = new BehaviorSubject<any>('');
  cast = this.userLangChange.asObservable();
  newDistSubject = new BehaviorSubject<any>(false);
  newDistEvent = this.newDistSubject.asObservable();

  private whiteLabelSubject = new BehaviorSubject<any>(null);
  whitelabelConfig = this.whiteLabelSubject.asObservable();

  refreshApiErroralert:  BehaviorSubject<any>;
  refreshApiErrorEvent:any;
  constructor() {
    this._emitter = new EventEmitter ( );
    this._emitter_removegrpmember = new EventEmitter ( );

    this.readjustDataTableSubject = new BehaviorSubject<any>({});
    this.readjustDataTableEvent = this.readjustDataTableSubject.asObservable();

    this.refreshDataTableSubject = new BehaviorSubject<any>({});
    this.refreshDataTableEvent = this.refreshDataTableSubject.asObservable();

    this.refreshApiErroralert = new BehaviorSubject<any>(false);
    this.refreshApiErrorEvent = this.refreshApiErroralert.asObservable();
  }

  subscribeReadjustDataTable(next, err?, complete?){
    return this.readjustDataTableEvent.subscribe(next, err, complete);
  }

  publishReadjustDataTable(data){
    this.readjustDataTableSubject.next(data);
  }

  subscribeRefreshDataTable(next, err?, complete?){
    return this.refreshDataTableEvent.subscribe(next, err, complete);
  }

  publishRefreshDataTable(data){
    this.refreshDataTableSubject.next(data);
  }

  subscribeToServiceEmitter ( callback ) {
    return this._emitter.subscribe ( b => callback ( b ) );
  }

  removemeberfrmGrp ( callback ) {
    return this._emitter_removegrpmember.subscribe ( b => callback ( b ) );
  }
  
  removeMemberFrmGrp ( data ) {
    this._emitter_removegrpmember.emit ( data );
  }

  emitThisData ( data ) {
    this._emitter.emit ( data );
  }
  emitLoginLanguageChange ( data ) {
    this.langLoginChange.emit (data );
  }
  emitPreviosUrl(url){
    this.previousUrl=url;
  }
  emitReloadDygraph ( data ) {
    this.reloadDygraph.emit (data );
  }

  emitUserLanguageChange( data){
    this.userLangChange.next(data);
  }

  subscribeapiError(data){
    this.refreshApiErroralert.next(data);
  }

  setWhiteLabelConfig(data){
    this.whiteLabelSubject.next(data);
  }
}