import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AppService } from '@app/app.service';
import { EmitService } from '@app/ts/services/emit.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { CommonService } from '../services/common.service';

@Injectable()
export class AuthGuard implements CanActivate {
  accounts_url: any;
  constructor(private router: Router,private appService : AppService,private commonservice:CommonService,private http: HttpClient,private emitService: EmitService) {
    this.apiUrl = localStorage.getEncryptedItem('API_END_POINT_ACCOUNTS');
    this.accounts_url = localStorage.getEncryptedItem('ACCOUNTS_URL');
  }
  routerPermission: any;
  apiUrl = '';
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean>|Promise<boolean>|boolean {
    let cookieExist = this.commonservice.getCookie(environment.checkCookie);
    if (cookieExist == "") {
      this.router.navigate(['/auth/login'])
    }
    else{
      let expiredCookie = this.commonservice.decrypt(decodeURIComponent(cookieExist))
      let formData = new FormData();
      formData.append("domain","."+localStorage.getEncryptedItem('domain'));
      if (this.tokenExpired(expiredCookie)) {
        // token expired
        this.appService.createAccessToken(formData).subscribe(data=>{ });
      } else {
        // token valid
      }
      if( !localStorage.getEncryptedItem('userData') ){
        let formData = new FormData();
        formData.append('code','EDI');
        let objectUser = this.commonservice.createObject(formData);
        let tokenUser = this.commonservice.createToken(objectUser);
        let encryptedTokenUser = this.commonservice.encrypt(tokenUser);
        let encrDataUser = new FormData();
        encrDataUser.append("encrToken", encryptedTokenUser);
        let tempPath = window.location.hash.replace('#/','') == '' ? 'profile' : window.location.hash.replace('#/','');
        this.http.post(this.apiUrl+'users/user-profile',encrDataUser).subscribe(dataFromApi => {
          let user = dataFromApi['data'];
          let userName = user[0].username;
          let companyList = user[0].companyDetails;
          let companyName = companyList.filter(item=>item.partyid == localStorage.getEncryptedItem('partyID'))[0]['companyname'];
          let userInfo = {};
          userInfo["userName"] = userName;
          userInfo["companyName"] = companyName;
          this.commonservice.setCookie(this.commonservice.encrypt(environment.userInfo),this.commonservice.encrypt(JSON.stringify(userInfo)),10000,environment.cookieDomain,'/');
          let permissions = String(user[0].permissions.Allow);
          this.commonservice.setCookie(environment.permissions,this.commonservice.encrypt(permissions),10000,environment.cookieDomain,'/');
          // localStorage.setEncryptedItem('permissions',permissions);
          localStorage.setEncryptedItem('userData', JSON.stringify(user));
          localStorage.setEncryptedItem('accId',user[0].accId);
          localStorage.setEncryptedItem('isCompanyConfirmed',user[0].isConfirmedParty.toString());
          localStorage.setEncryptedItem('isAddressEmpty',user[0].isAddressEmpty.toString());
          localStorage.setEncryptedItem('hasPermission',user[0].hasPermission.toString());
          localStorage.setEncryptedItem('hideTutorial',user[0].hidetutorial.toString());
          let permissionCookie = this.commonservice.getCookie(environment.permissions);
          
          if(this.commonservice.decrypt(decodeURIComponent(permissionCookie)).indexOf(route.data.right)==-1){
            window.location.href = this.accounts_url+'auth/logout/';
          }
          window.location.href = this.accounts_url + tempPath;
          
          setTimeout(() => { 
            let language = localStorage.getEncryptedItem('userData') ? JSON.parse(localStorage.getEncryptedItem('userData'))[0].language: "de";
            language = user[0].language ? user[0].language : 'de';
            this.emitService.emitUserLanguageChange(language);
            // this.emitService.emitLoginLanguageChange('en');
          },100);
          window.location.href = this.accounts_url + tempPath;
        });
      } 
      else{
        let permissionCookie = this.commonservice.getCookie(environment.permissions);        
        if(this.commonservice.decrypt(decodeURIComponent(permissionCookie)).indexOf(route.data.right)==-1){
          window.location.href = this.accounts_url+'auth/logout/';
        }
        return true;
      }
      
    }
    
  }

  private tokenExpired(token) {
    let cookieExpiredTime = JSON.parse(token)
    return new Date(cookieExpiredTime[environment.tokenExpireTime]).getTime() < new Date().getTime();
  }
  
}
